import {HTTP_INTERCEPTORS} from '@angular/common/http'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {AuthInterceptor} from './application/auth-interceptor'
import {ResponseInterceptor} from './application/response-interceptor'
import {SpbCommonModule} from './common/common.module'
import {ConfigService} from './services/config.service'

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SpbCommonModule,
    ThemeModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }