import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {HOME_ROUTE_PATH, LOGIN_ROUTE_PATH} from './application/types'
import {authGuard} from './application/auth.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}